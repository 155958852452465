import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { FaFolderOpen, FaLinkedin, FaUserGraduate } from 'react-icons/fa';

class Homefooter extends Component {
  render() {
    return (
      <div className='footer-color'>
        <div className='social-links'>
          {/* About */}
          <Link to='/aboutme'>
            <i>
              <FaUserGraduate />
            </i>
          </Link>

          {/* Portfolio */}
          <Link to='/portfolio'>
            <i>
              <FaFolderOpen />
            </i>
          </Link>

          {/* LinkedIn */}
          <a
            href='https://www.linkedin.com/in/anthony-cruz-0693a218b'
            rel='noopener noreferrer'
            target='_blank'
          >
            <i>
              <FaLinkedin />
            </i>
          </a>

          {/* Email */}
          <a
            href='mailto:cruza14@unlv.nevada.edu?Subject=Hi%20Anthony!'
            rel='noopener noreferrer'
            target='_blank'
          >
            <i className='fa fa-envelope' aria-hidden='true' />
          </a>
        </div>
      </div>
    );
  }
}

export default Homefooter;
