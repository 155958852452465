import React, { Component } from 'react';
import { Header, Navigation, Layout, Drawer, Content, Button } from 'react-mdl';
import { Link } from 'react-router-dom';
import Resume from './resume';

class About extends Component {
  render() {
    return (
      <div>
        <Layout>
          {/* Header */}
          <Header className='header-color' title=' '>
            <Navigation>
              <Link to='/'>Home</Link>
              <Link to='/aboutme'>About</Link>
              <Link to='/portfolio'>Portfolio</Link>
              <a
                href={process.env.PUBLIC_URL + '/anthony_cruz.pdf'}
                rel='noopener noreferrer'
                target='_blank'
              >
                <Button
                  raised
                  accent
                  ripple
                  style={{
                    backgroundColor: '#e22947',
                    color: 'white'
                  }}
                >
                  Resume
                </Button>
              </a>
            </Navigation>
          </Header>
          {/* Drawer */}
          <Drawer title='Anthony Cruz'>
            <Navigation>
              <Link to='/'>Home</Link>
              <Link to='/aboutme'>About</Link>
              <Link to='/portfolio'>Portfolio</Link>
              <a
                href={process.env.PUBLIC_URL + '/anthony_cruz.pdf'}
                rel='noopener noreferrer'
                target='_blank'
              >
                <Button
                  raised
                  accent
                  ripple
                  style={{
                    backgroundColor: '#e22947',
                    color: 'white'
                  }}
                >
                  Resume
                </Button>
              </a>
            </Navigation>
          </Drawer>
          {/* Body */}
          <Content className='white-background'>
            <div>
              <Resume />
            </div>
          </Content>
        </Layout>
      </div>
    );
  }
}

export default About;
