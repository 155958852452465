import React, { Component } from 'react';
import {
  Tabs,
  Tab,
  Grid,
  Cell,
  Header,
  Navigation,
  Layout,
  Drawer,
  Content,
  Button
} from 'react-mdl';
import { Link } from 'react-router-dom';
import Unlv from './images/unlv.png';
import Coding from './coding';
import Capstone from './capstone';

class Projects extends Component {
  constructor(props) {
    super(props);
    this.state = { activeTab: 0 };
  }

  toggleCategories() {
    if (this.state.activeTab === 0) {
      return (
        <div className='cs-courses-tab'>
          {/* Major courses */}
          <Grid>
            {/* UNLV Logo */}
            <Cell col={4}>
              <div className='text-center'>
                <img src={Unlv} className='unlv' />
              </div>
            </Cell>

            {/* Courses*/}
            <Cell col={8}>
              <h3 className='h3'>Major-related Courses</h3>
              <hr style={{ borderTop: '3px solid #d3d3d3' }} />
              <ul>
                <h6>
                  <li>CPE 100 - Digital Logic Design I/L</li>
                  <li>CS 135 - Computer Science I/L (C++)</li>
                  <li>CS 202 - Computer Science II (C++)</li>
                  <li>
                    CS 218 - Introduction to Systems Programming (x86/MIPS)
                  </li>
                  <li>CS 219 - Computer Organization</li>
                  <li>CS 301 - Social Implications of Computer Technology</li>
                  <li>CS 302 - Data Structures (C++)</li>
                  <li>
                    CS 326 - Programming Languages, Concepts and Implementation
                    (Java, Python, Lisp, Quorum)
                  </li>
                  <li>CS 370 - Operating Systems (C, Xv6)</li>
                  <li>CS 443 - Information Assurance</li>
                  <li>CS 456 - Automata and Formal Languages</li>
                  <li>CS 457 - Database Management Systems (MariaDB)</li>
                  <li>CS 460 - Compiler Construction (Java)</li>
                  <li>
                    CS 472 - Software Product Design I (Ionic Framework,
                    HTML/CSS, TypeScript)
                  </li>
                  <li>CS 477 - Analysis of Algorithms</li>
                  <li>CS 488 - Big Data Analytics (Python)</li>
                  <li>CS 489 - Building Applications in Cloud (Salesforce)</li>
                  <li>MATH 181 - Calculus I</li>
                  <li>MATH 182 - Calculus II</li>
                  <li>MATH 251 - Discrete Mathematics I</li>
                  <li>MATH 351 - Discrete Mathematics II</li>
                  <li>MATH 365 - Computational Linear Algebra</li>
                  <li>STAT 411 - Statistical Methods I</li>
                </h6>
              </ul>
            </Cell>
          </Grid>
          <hr style={{ borderTop: '3px solid #d3d3d3' }} />
        </div>
      );
    } else if (this.state.activeTab === 1) {
      return (
        <div>
          {/* Course Projects */}
          <Coding />
        </div>
      );
    } else if (this.state.activeTab === 2) {
      return <Capstone />;
    }
  }

  render() {
    return (
      <div>
        <Layout>
          {/* Header */}
          <Header className='header-color' title=' '>
            <Navigation>
              <Link to='/'>Home</Link>
              <Link to='/aboutme'>About</Link>
              <Link to='/portfolio'>Portfolio</Link>
              <a
                href={process.env.PUBLIC_URL + '/anthony_cruz.pdf'}
                rel='noopener noreferrer'
                target='_blank'
              >
                <Button
                  raised
                  accent
                  ripple
                  style={{
                    backgroundColor: '#e22947',
                    color: 'white'
                  }}
                >
                  Resume
                </Button>
              </a>
            </Navigation>
          </Header>

          {/* Drawer */}
          <Drawer title='Anthony Cruz'>
            <Navigation>
              <Link to='/'>Home</Link>
              <Link to='/aboutme'>About</Link>
              <Link to='/portfolio'>Portfolio</Link>
              <a
                href={process.env.PUBLIC_URL + '/anthony_cruz.pdf'}
                rel='noopener noreferrer'
                target='_blank'
              >
                <Button
                  raised
                  accent
                  ripple
                  style={{
                    backgroundColor: '#e22947',
                    color: 'white'
                  }}
                >
                  Resume
                </Button>
              </a>
            </Navigation>
          </Drawer>

          {/* Body */}
          <Content className='white-background'>
            <div>
              <Tabs
                activeTab={this.state.activeTab}
                onChange={tabId => this.setState({ activeTab: tabId })}
                ripple
              >
                <Tab className='tabs'>Courses</Tab>
                <Tab className='tabs'>Projects</Tab>
                <Tab className='tabs'>Capstone</Tab>
              </Tabs>

              <Grid>
                <Cell col={12}>
                  <div className='content'>{this.toggleCategories()}</div>
                </Cell>
              </Grid>
            </div>
          </Content>
        </Layout>
      </div>
    );
  }
}

export default Projects;
