import React, { Component } from 'react';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import { Link } from 'react-router-dom';
import { Navigation, Layout, Drawer, Button } from 'react-mdl';
import Home1 from './images/home1.png';
import Home2 from './images/home2.png';
import Home3 from './images/home3.png';
import HomeFooter from './homefooter';

function Images() {
  return (
    <Carousel
      showThumbs={false}
      showStatus={false}
      infiniteLoop={true}
      useKeyboardArrows={true}
      dynamicHeight={true}
    >
      <div>
        <img src={Home1} className='cImages' />
      </div>
      <div>
        <img src={Home2} className='cImages' />
      </div>
      <div>
        <img src={Home3} className='cImages' />
      </div>
    </Carousel>
  );
}

class Landing extends Component {
  render() {
    return (
      <div>
        <Layout>
          {/* Drawer */}
          <Drawer title='Anthony Cruz'>
            <Navigation>
              <Link to='/'>Home</Link>
              <Link to='/aboutme'>About</Link>
              <Link to='/portfolio'>Portfolio</Link>
              <a
                href={process.env.PUBLIC_URL + '/anthony_cruz.pdf'}
                rel='noopener noreferrer'
                target='_blank'
              >
                <Button
                  raised
                  accent
                  ripple
                  style={{
                    backgroundColor: '#e22947',
                    color: 'white'
                  }}
                >
                  Resume
                </Button>
              </a>
            </Navigation>
          </Drawer>

          {/* Landing page components */}
          <div className='carousel-width'>
            <Images />
            <HomeFooter />
          </div>
        </Layout>
      </div>
    );
  }
}

export default Landing;
