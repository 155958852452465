import React, { Component } from 'react';
import { Grid, Cell } from 'react-mdl';
import Biokey from './images/biokey.png';
import Details from './images/techdetails.png';
import Ionic from './images/ionic.png';
import Html from './images/html.png';
import Css from './images/css.png';
import Ts from './images/typescript.png';
import Award from './biokey/award.jpg';
import Apple from './biokey/apple.png';
import Android from './biokey/android.png';
import Www from './biokey/www.png';

class Capstone extends Component {
  render() {
    return (
      <Grid>
        {/* Capstone Project */}
        <div className='capstone-tab'>
          {/* Title */}
          <Cell col={12}>
            <img src={Award} className='award-photo' />
          </Cell>
          <Cell col={12}>
            <h3 className='capstone-title'>
              CS 472 - Software Product Design Capstone Project:&nbsp;
              <strong>BioKey Security</strong>
            </h3>
          </Cell>

          <hr style={{ borderTop: '3px solid #d3d3d3' }} />

          {/* About */}
          <Cell col={12}>
            <div className='text-center'>
              <img src={Biokey} className='biokey-logo' />
            </div>
          </Cell>
          <Cell col={12}>
            <p className='p'>
              For this project I worked with three fellow classmates to create
              and design a smart security system that integrated features of
              some diverse products that are currently on the market into a
              single unit. This is beneficial for both home and business
              security. Security issues are seldom common to all users, yet many
              of the on-market products require constant monitoring or are
              tailored to only protect one aspect of security (e.g. smart door
              lock, live video doorbell, 24/7 video surveillance onto hard
              storage). Our plan was to barebones the system into notifying and
              asking the user what actions to take when absolutely necessary,
              creating a security/automation plus solution.
            </p>
            <p className='p'>
              Essentially, we created an all-in-one biometrics-based security
              system and used facial recognition and voice pass-phrases to add
              an extra layer of security for a real world two-factor
              authentication system. My task for this project was to implement
              the User Interface using Ionic Framework (HTML, CSS, Typescript)
              to make our application cross-platform for iOS, Android, and the
              Web. I also helped set up the API calls to connect the UI with our
              backend and ensured clear and concise communication between myself
              and the backend developers.
            </p>
          </Cell>

          <hr style={{ borderTop: '3px solid #d3d3d3' }} />

          {/* Backend */}
          <div className='text-center'>
            <Cell col={12}>
              <h5 className='h5'>Backend Technologies</h5>
            </Cell>

            <Cell col={12}>
              <img src={Details} className='biokey-details' />
            </Cell>
          </div>

          {/* Frontend */}
          <div className='text-center'>
            <Cell col={12}>
              <h5 className='h5'>Frontend Technologies</h5>
            </Cell>

            <img src={Ionic} className='frontend-logos' />
            <img src={Html} className='frontend-logos' />
            <img src={Ts} className='frontend-logos-width' />
            <img src={Css} className='frontend-logos' />
          </div>

          <hr style={{ borderTop: '3px solid #d3d3d3' }} />

          {/* User Interface */}
          <div className='text-center'>
            <Cell col={12}>
              <h5 className='h5'>User Interface</h5>
            </Cell>

            <img
              src={process.env.PUBLIC_URL + '/bio1.png'}
              className='biokey-app'
            />
            <img
              src={process.env.PUBLIC_URL + '/bio2.png'}
              className='biokey-app'
            />
            <img
              src={process.env.PUBLIC_URL + '/bio3.png'}
              className='biokey-app'
            />
            <img
              src={process.env.PUBLIC_URL + '/bio4.png'}
              className='biokey-app'
            />
            <img
              src={process.env.PUBLIC_URL + '/bio5.png'}
              className='biokey-app'
            />
            <img
              src={process.env.PUBLIC_URL + '/bio6.png'}
              className='biokey-app'
            />
            <img
              src={process.env.PUBLIC_URL + '/bio7.png'}
              className='biokey-app'
            />
            <img
              src={process.env.PUBLIC_URL + '/bio8.png'}
              className='biokey-app'
            />

            <div>
              <img src={Apple} className='frontend-logos' />
              <img src={Www} className='frontend-logos' />
              <img src={Android} className='frontend-logos' />
            </div>
          </div>
        </div>
      </Grid>
    );
  }
}
export default Capstone;
