import React, { Component } from 'react';
import {
  Grid,
  Cell,
  Card,
  CardTitle,
  CardText,
  CardActions,
  Button
} from 'react-mdl';

class Coding extends Component {
  render() {
    return (
      <Grid>
        {/* Project 1 */}
        <Cell col={4} phone={4} tablet={4}>
          <Card shadow={5} className='cards'>
            <CardTitle
              style={{
                color: 'black',
                height: '215px',
                background:
                  'url(https://i.imgur.com/uFGBWIO.png) center / cover'
              }}
            ></CardTitle>
            <CardText>
              <hr style={{ borderTop: '3px solid black' }} />
              <p>
                Project that involved writing an interpreter for an assembler
                like language (very closely related to Java bytecode) consisting
                of 4 phases.
              </p>
              <ul>
                <p>
                  <li>Phase 1 - Implementing and testing the operand stack.</li>
                  <li>
                    Phase 2 - Implementing and testing a number of operations
                    involving the operand stack.
                  </li>
                  <li>
                    Phase 3 - Working with activation records and the execution
                    loops; implementing instructions concerned with moving data
                    from activation records to the operand stack and back.
                  </li>
                  <li>
                    Phase 4 - Creating new objects, accessing fields, and
                    invoking methods and constructors.
                  </li>
                </p>
              </ul>
            </CardText>
            <CardActions border>
              <div className='text-center'>
                {/*  GitHub */}
                <a
                  href='http://www.egr.unlv.edu/~matt/teaching/CSC326/evm.pdf'
                  rel='noopener noreferrer'
                  target='_blank'
                >
                  <Button style={{ width: '100%' }} colored>
                    <strong>Espresso Virtual Machine</strong>
                  </Button>
                </a>
              </div>
            </CardActions>
          </Card>
        </Cell>

        {/* Project 2 */}
        <Cell col={4} phone={4} tablet={4}>
          <Card shadow={5} className='cards'>
            <CardTitle
              style={{
                color: 'black',
                height: '242px',
                background:
                  'url(https://csharp-station.com/wp-content/uploads/2018/05/C-language.jpg) center / cover'
              }}
            ></CardTitle>
            <CardText>
              <hr style={{ borderTop: '3px solid black' }} />
              <p>
                Implemented a time system call with an optional parameter (-s)
                to show time in seconds.
              </p>
              <p>
                Expanded the Xv6 file system to include basic file permissions
                by first implementing the chmod (“change mode”) system call, and
                subsequently adjusting the open system call to check the newly
                created file permissions before executing.
              </p>
              <p>
                Implemented a non-preemptive FIFO scheduler and wrote a
                benchmarking program to test various results of the FIFO
                scheduler compared to the default xv6 Round Robin scheduler.
              </p>
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
              <br />
            </CardText>
            <CardActions border>
              <div className='text-center'>
                {/*  GitHub */}
                <a
                  href='https://pdos.csail.mit.edu/6.828/2018/xv6/book-rev11.pdf'
                  rel='noopener noreferrer'
                  target='_blank'
                >
                  <Button style={{ width: '100%' }} colored>
                    <strong>Xv6 (Operating System)</strong>
                  </Button>
                </a>
              </div>
            </CardActions>
          </Card>
        </Cell>

        {/* Project 3 */}
        <Cell col={4} phone={4} tablet={4}>
          <Card shadow={5} className='cards'>
            <CardTitle
              style={{
                color: '#fff',
                height: '225px',
                background:
                  'url(https://i.imgur.com/8Qj77eg.png) center / cover'
              }}
            ></CardTitle>
            <CardText>
              <hr style={{ borderTop: '3px solid black' }} />
              <p>
                Implemented a compiler for a language called Espresso; Espresso
                is a subset of real Java. This project consisted of 6 phases
                that followed a model of a compiler.
              </p>
              <ul>
                <p>
                  <li>
                    Phase 1 — Using the tools JFlex and Java CUP to construct a
                    scanner and a parser for the Espresso language.
                  </li>
                  <li>
                    Phase 2 — Add action to the parser to build a syntax tree
                    for the parsed program.
                  </li>
                  <li>Phase 3 — Add a symbol table to the project.</li>
                  <li>Phase 4 — Perform type checking.</li>
                  <li>Phase 5 — Modifier checking.</li>
                  <li>
                    Phase 6 — Generate intermediate assembler code, which can be
                    assembled to Java class files using Jasmin.
                  </li>
                </p>
              </ul>
            </CardText>
            <CardActions border>
              <div className='text-center'>
                {/*  GitHub */}
                <a
                  href='http://www.egr.unlv.edu/~matt/teaching/CSC460/Espresso.pdf'
                  rel='noopener noreferrer'
                  target='_blank'
                >
                  <Button style={{ width: '100%' }} colored>
                    <strong>Espresso Compiler Project</strong>
                  </Button>
                </a>
              </div>
            </CardActions>
          </Card>
        </Cell>

        {/* Project 4 */}
        <Cell col={4} phone={4} tablet={4}>
          <Card shadow={5} className='cards'>
            <CardTitle
              style={{
                color: 'black',
                height: '205px',
                background:
                  'url(https://i.imgur.com/YDHoLG5.png) center / cover'
              }}
            >
              Portfolio
            </CardTitle>
            <CardText>
              <hr style={{ borderTop: '3px solid black' }} />
              <p>
                My goal was to build a portfolio website for myself while
                learning a new framework. Due to its popularity, React was an
                obvious choice to learn next. React is component based which I
                think is neat and convenient when reusing code for certain
                segments.
              </p>
              <p>
                For the components, I coded in JSX (JavaScript XML) and CSS for
                the styling. I used React-MDL library for some components and
                the navigation layout.
              </p>
              <p>
                The website is hosted on Netlify where it is connected to my
                GitHub repository which allows for continuous live deployments.
                I will continue to update this website as I learn more about
                React and the awesome features it possesses.
              </p>
              <br />
            </CardText>
            <CardActions border>
              <div className='text-center'>
                {/*  GitHub */}
                <a
                  href='https://www.anthony-cruz.com'
                  rel='noopener noreferrer'
                  target='_blank'
                >
                  <Button style={{ width: '100%' }} colored>
                    <strong>www.anthony-cruz.com</strong>
                  </Button>
                </a>
              </div>
            </CardActions>
          </Card>
        </Cell>

        {/* Project 5 */}
        <Cell col={4} phone={4} tablet={4}>
          <Card shadow={5} className='cards'>
            <CardTitle
              style={{
                color: 'black',
                height: '208px',
                background:
                  'url(https://i.imgur.com/907xdv2.png) center / cover'
              }}
            >
              Big Data
            </CardTitle>
            <CardText>
              <hr style={{ borderTop: '3px solid black' }} />
              <p>
                For a project in my Big Data class, I gathered two datasets to
                run a study on detecting spam for both Email and SMS text
                messages. For the chosen datasets, I implemented Naïve Bayes and
                Logistic regression using Python’s Scikit-Learn library for
                machine learning. Scikit provides ready-made Naïve Bayes
                classifiers for precise data modeling so I coded this study in
                Python to take advantage of this useful library.
              </p>
              <p>
                One of the datasets contained real email messages from the Enron
                Email dataset. The algorithms yielded impressive results with a
                99% detection rate of spam messages. This study showed me how
                powerful machine learning can be and why this field has been
                growing in popularity.
              </p>
              <br />
            </CardText>
            <CardActions border>
              <div className='text-center'>
                {/*  GitHub */}
                <a
                  href='https://github.com/ant-c/machine-learning'
                  rel='noopener noreferrer'
                  target='_blank'
                >
                  <Button style={{ width: '50%' }} colored>
                    <strong>GitHub</strong>
                  </Button>
                </a>

                <a
                  href={process.env.PUBLIC_URL + '/anthony_cruz_big-data.pdf'}
                  rel='noopener noreferrer'
                  target='_blank'
                >
                  <Button style={{ width: '50%' }} colored>
                    <strong>PDF</strong>
                  </Button>
                </a>
              </div>
            </CardActions>
          </Card>
        </Cell>

        {/* Project 6 */}
        <Cell col={4} phone={4} tablet={4}>
          <Card shadow={5} className='cards'>
            <CardTitle
              style={{
                color: '#fff',
                height: '210px',
                background:
                  'url(https://i.imgur.com/VeETKPC.png) center / cover'
              }}
            ></CardTitle>
            <CardText>
              <hr style={{ borderTop: '3px solid black' }} />
              <p>
                For this assignment, we tested the differences between threads
                vs. processes in Python. Due to Python’s GIL (Global Interpreter
                Lock), two threads are prevented from executing simultaneously
                in the same program. This makes threads not useful for CPU
                intensive tasks in Python. A solution to this is to apply
                processes to speed up execution time for CPU intensive programs.
              </p>
              <p>
                This program takes in two input files (A/B matrix) and the
                number of threads from the user. Upon valid input, matrix
                computation sections are split and the threads are fired off to
                execute their portion of the matrix multiplication. After all
                threads have completed their work, the result is displayed in an
                output file. The execution time dramatically improved compared
                to the multi-threaded approach!
              </p>
            </CardText>
            <CardActions border>
              <div className='text-center'>
                {/*  GitHub */}
                <a
                  href='https://github.com/ant-c/matrix-multiplication'
                  rel='noopener noreferrer'
                  target='_blank'
                >
                  <Button style={{ width: '100%' }} colored>
                    <strong>GitHub</strong>
                  </Button>
                </a>
              </div>
            </CardActions>
          </Card>
        </Cell>
      </Grid>
    );
  }
}
export default Coding;
