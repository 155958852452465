import React, { Component } from 'react';
import { Grid, Cell } from 'react-mdl';

class Education extends Component {
  render() {
    return (
      <Grid>
        <Cell col={4}>
          {/* Jan. 2017 - Jan. 2018 */}
          <p>
            {this.props.startDate}
            {this.props.startYear}
            {this.props.endDate}
            {this.props.endYear}
          </p>
        </Cell>

        {/* UNLV - desc. */}
        <Cell col={8}>
          <h4 style={{ marginTop: '0px' }}>{this.props.schoolName}</h4>
          <p style={{ fontStyle: 'italic' }} className='p'>
            {this.props.schoolDescription}
          </p>
        </Cell>
      </Grid>
    );
  }
}

export default Education;
