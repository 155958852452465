import React from 'react';
import { Switch, Route } from 'react-router-dom';
import LandingPage from './landingpage';
import AboutMe from './aboutme';
import Projects from './projects';

const Main = () => (
  <Switch>
    <Route exact path='/' component={LandingPage} />
    <Route path='/aboutme' component={AboutMe} />
    <Route path='/portfolio' component={Projects} />
  </Switch>
);

export default Main;
