import React, { Component } from 'react';
import { Grid, Cell, Button } from 'react-mdl';
import Education from './education';
import Experience from './experience';
import Skills from './skills';
import Profile from './images/profile.png';

class Resume extends Component {
  render() {
    return (
      <div>
        <Grid>
          <Cell col={4} tablet={2}>
            {/* Profile image */}
            <div style={{ textAlign: 'center' }}>
              <img src={Profile} alt='avatar' className='about-photo' />
            </div>

            <h2 style={{ textAlign: 'center' }} className='h2'>
              Anthony Cruz
            </h2>

            {/* About Me */}
            <h4 className='h4' style={{ color: 'grey' }}>
              About Me
            </h4>
            <hr style={{ borderTop: '3px solid #833fb2', width: '50%' }} />
            <p className='p'>
              Hello! I’m Anthony, an undergraduate student at University of
              Nevada, Las Vegas, majoring in Computer Science. Currently, I’m in
              my senior year and expected to graduate December 17, 2019. I’ve
              always had a passion for computers but after taking my first
              Computer Science course in college, I knew this is what I wanted
              to do.
            </p>
            <p className='p'>
              My desire is to obtain a career in Software Engineering. I enjoy
              solving problems and finding ways to make our daily lives simpler
              and easier; so that we can optimize our time for things that
              matter most to us. Technology has become part of our everyday
              living with endless possibilities and I’m excited to be a part of
              what is next to come.
            </p>

            <hr style={{ borderTop: '3px solid #833fb2', width: '50%' }} />

            {/* Contact Info */}
            <h5>
              <strong>Address</strong>
            </h5>
            <p>Las Vegas, NV</p>
            <h5>
              <strong>Phone</strong>
            </h5>
            <p>(702) 517-9193</p>
            <h5>
              <strong>Email</strong>
            </h5>

            <a
              href='mailto:cruza14@unlv.nevada.edu?Subject=Hi%20Anthony!'
              rel='noopener noreferrer'
              target='_blank'
              style={{ color: 'blue' }}
            >
              <p style={{ color: 'blue' }}>cruza14@unlv.nevada.edu</p>
            </a>
            <h5>
              <strong>Web</strong>
            </h5>

            <p>www.anthony-cruz.com</p>

            <hr style={{ borderTop: '3px solid #833fb2', width: '50%' }} />
          </Cell>

          {/* Education */}
          <Cell className='resume-right-col' col={8} tablet={6}>
            <h3 className='h3'>Education</h3>
            <Education
              startDate='Jan. '
              startYear={2018}
              endDate=' - Dec. '
              endYear={2019}
              schoolName='University of Nevada, Las Vegas'
              schoolDescription='B.S. in Computer Science'
            />
            <Education
              startDate='Jan. '
              startYear={2016}
              endDate=' - Dec. '
              endYear={2017}
              schoolName='College of Southern Nevada'
              schoolDescription='A.S. in Science'
            />
            <hr style={{ borderTop: '3px solid #e22947' }} />

            {/* Awards */}
            <h3 className='h3'>Achievements</h3>
            <Experience
              startDate='Spring '
              endYear={2019}
              jobName='Award'
              jobDescription='Spring semester of 2019, I participated in a group project for Senior Design. Our team created an all-in-one biometrics-based security system, using facial recognition and voice pass-phrases. Competing against our fellow classmates our group won 2nd place and received the "Fred & Harriet Cox Senior Design Competition" Award.'
            />
            <Experience
              startDate='Fall 2018 / Spring '
              endYear={2019}
              jobName='Academic Success'
              jobDescription="My current GPA at University of Nevada, Las Vegas, is a 3.8. I have been on the Dean's Honor List every semester since Fall 2018 and will be graduating with Cum Laude Honors this December."
            />
            <Experience
              startDate='Fall '
              endYear={2017}
              jobName='Academic Success'
              jobDescription='During my studies at College of Southern Nevada, I obtained a 3.93 GPA and graduated with High Honors.'
            />
            <hr style={{ borderTop: '3px solid #e22947' }} />

            {/* Skills */}
            <h3 className='h3'>Skills</h3>
            <Skills skill='C' progress={50} />
            <Skills skill='C++' progress={75} />
            <Skills skill='CSS/HTML' progress={78} />
            <Skills skill='Ionic' progress={80} />
            <Skills skill='Java' progress={80} />
            <Skills skill='Javascript' progress={60} />
            <Skills skill='Python' progress={70} />
            <Skills skill='React' progress={35} />
            <Skills skill='SQL' progress={20} />
            <Skills skill='Typescript' progress={60} />
          </Cell>
        </Grid>
      </div>
    );
  }
}
export default Resume;
